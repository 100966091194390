import { useTheme } from '@mui/material/styles';
import { isIphone } from '../modules/utils';
import backgroundImageMain from '../images/diving-over-shark.jpg';
import searchBackgroundVideo from '../videos/greece.mp4';

const LandingPageVideo = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '88vh',
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(${backgroundImageMain})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      {!isIphone() && (
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        >
          <source src={searchBackgroundVideo} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default LandingPageVideo;

/*
<video
  autoPlay
  loop
  poster={isIphone() ? backgroundImageMain : ''}
  muted
  style={{
    position: 'absolute',
    width: '100%',
    objectFit: 'cover',
    height: '88vh'
  }}
>
  {!isIphone() && (
    <source src={searchBackgroundVideo} type="video/mp4" />
  )}
</video>
*/
